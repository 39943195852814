import React from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';

function MailDetail(props) {
  const [actions] = useOutlet('actions');
  const {record} = props;
  const defaultValue = {
    name: '',
    image: '',
    order: 1,
  };

  if (!record) {
    return null;
  }

  return (
    <Wrapper>
      <h2>信件內容</h2>
      <section>
        <div className="field">
          <label>時間</label>
          <p>{Moment(record.created).format('YYYY-MM-DD HH:mm:ss')}</p>
        </div>
        <div className="field">
          <label>顧客名字</label>
          <p>{record.name}</p>
        </div>
        <div className="field">
          <label>性別</label>
          <p>{record.gender}</p>
        </div>
        <div className="field">
          <label>院所</label>
          <p>{record.type}</p>
        </div>
        <div className="field">
          <label>{record.contact_type}</label>
          <p>{record.contact_data}</p>
        </div>
        <div className="field">
          <label>方便聯繫時間</label>
          <p>{record.freetime}</p>
        </div>

        <div className="field">
          <label>詢問內容</label>
          <p>{record.content}</p>
        </div>
      </section>

      {/* <div className="action-bar">
        <Ant.Button
          type="primary"
          style={{marginRight: 10}}
          onClick={}>
            確認
        </Ant.Button>
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;

  & > h2 {
    padding: 20px;
  }

  & > section {
    padding: 20px;
    border-bottom: 1px solid #ccc;

    & > .field {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      & > label {
        min-width: 100px;
      }
    }
  }
`;

export default MailDetail;
