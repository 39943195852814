import React from 'react';
import * as Ant from 'antd';
import * as Generic from '../../Generic';
import MailDetail from './MailDetail';
import * as AppActions from '../../AppActions';
import * as JStorageActions from '../../Actions/JStorage';

function AdminMails(props) {
  const [selectRecordModal, setSelectRecordModal] = React.useState(null);

  const fetchRecords = React.useCallback(() => {
    return JStorageActions.fetchDocuments('mail');
  }, []);

  return (
    <>
      <Generic.Resource
        spec={{
          path: '/dashboard/mails',
          name: '顧客訊息',
          primaryKey: 'id',
          actions: {
            setLoading: AppActions.setLoading,
            fetchRecords,
          },
          columns: [
            {
              title: '日期',
              key: 'created',
              dataIndex: 'created',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => b.created - a.created,
              render: (created) => {
                return new Date(created).toLocaleString();
              },
              // sortDirections: ['descend', 'ascend'],
            },
            {
              title: '顧客名稱',
              key: 'name',
              dataIndex: 'name',
            },
            {
              title: '院所',
              key: 'type',
              dataIndex: 'type',
            },
            {
              title: '內文',
              key: 'content',
              dataIndex: 'content',
            },
          ],
        }}
        // onCreate={() => {
        //   window.open(`${Config.articleEditorHost}?token=${user.token}`);
        // }}
        onGoToDetail={(record) => {
          setSelectRecordModal(record);
        }}
        renderCreateButton={null}
        renderDetail={() => null}
        {...props}
      />

      {!!selectRecordModal && (
        <Ant.Modal
          title={null}
          footer={null}
          bodyStyle={{padding: 0}}
          visible={true}
          onOk={() => setSelectRecordModal(null)}
          onCancel={() => setSelectRecordModal(null)}>
          <MailDetail key={new Date().getTime()} record={selectRecordModal} />
        </Ant.Modal>
      )}
    </>
  );
}

export default AdminMails;
